@import 'variables';

mat-sidenav.details-wrapper,
.mat-drawer.details-wrapper {
  width: 410px;
  position: fixed;
  right: 0;
  top: 148px;
  bottom: 0;
  background-color: $color-white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.15);
  color: #424242;
  font-family: $q9_font_family;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;

  &_grid {
    top: 98px;
  }

  &_sf-management {
    top: 115px;
  }

  overflow: visible;

  .sidebar-wrapper {
    transform: translate(0, 0);
    height: 100%;
    overflow: hidden;
    .sidebar-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      svg fill circle {
        width: 42px;
        height: 42px;
      }
      &--smaller {
        svg {
          width: 38px;
          height: 38px;
        }
      }
    }
  }
}

.item-details {
  transform: translate(0);
  overflow: hidden;
  height: 100%;
  padding-bottom: 80px;
  .mat-mdc-form-field-icon-suffix {
    align-self: center;
  }

  .item-title {
    padding: 0 15px;
    //padding: 0;
    //margin: 0;
    .mat-form-field-appearance-fill .mat-mdc-form-field-flex {
      padding-top: 0;
    }
    .mat-mdc-form-field-infix {
      padding: 0;
      border: none;
    }
    .mat-mdc-form-field-underline {
      background: none;
    }

    .mat-mdc-form-field {
      padding-top: 20px;

      .pencil-icon {
        width: 32px;
        height: 32px;
      }
    }
  }

  textarea {
    min-height: 20px;
  }

  mat-form-field {
    width: 100%;
  }

  .selected-row-name {
    font-size: 20px;
    font-weight: 300;
    border: none;
    margin: 15px 0 8px 0;
    color: $color-q9-section-data;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    white-space: nowrap;
  }

  h3,
  input.headline,
  p.headline {
    font-weight: 400;
    border: none;
    color: $color-q9-section-data;

    &.headline {
      width: 100%;
    }

    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    white-space: nowrap;

    &:disabled {
      background-color: $color-white;
    }
  }

  .rsb-buttons-panel {
    display: flex;
    align-items: center;
    padding-right: 0;
    //margin-top: -6px;

    button.mr-3 {
      margin-right: 3px;
    }

    .additional-action {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
  }

  .tabs-section {
    margin-top: 10px;
    padding-bottom: 115px;
    height: inherit;
    .mat-mdc-tab-body-content {
      // is needed for perfect-scroll
      // ( if we want to scroll not all _main from tab )
      overflow: hidden;
    }

    perfect-scrollbar {
      mat-card {
        padding: 0 0 70px 0;
      }
    }
    mat-card {
      padding: 0;
      box-shadow: none;
      border: none;
    }

    &:not(.q9-icon-tab-group) {
      .mat-mdc-tab-header {
        margin: 0 18px;
      }
    }

    .pull-right {
      float: right;
    }

    .rsb-toggle {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .inner-text {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        letter-spacing: 0;
      }

      .upgrade-pro-label {
        margin-top: -10px;
        small {
          font-style: italic;
        }
      }
    }

    .q9-input-label {
      display: block;
      font-weight: 400;
      color: $q9_brown;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 2px;
    }

    .q9-link {
      text-decoration: none;
      color: #337ab7;
    }

    .q9-checkbox {
      display: block;

      .mat-mdc-checkbox .mdc-form-field {
        margin-bottom: 0;
      }

      .mdc-checkbox {
        height: 13px;
        width: 13px;
        line-height: 20px;
        margin-bottom: 3px;

        input[disabled]:hover {
          cursor: not-allowed;
        }
      }

      .mat-mdc-checkbox .mdc-label {
        font-weight: 400;
        color: $color-q9-section-data;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .form-group {
      margin-top: 20px;
    }

    .form-group-row {
      margin: 20px 0 0 0;
    }
    .form-group p,
    .form-group-row p {
      margin: 0;
    }

    .rsb-share-list {
      padding-bottom: 100px;
      height: inherit;

      & > .form-group:first-child {
        margin-top: 0;
      }
    }

    .expand-list {
      padding-bottom: 10px;
      background-color: white;
      bottom: 0;
      box-shadow: 0 -5px 5px -5px rgba(51, 51, 51, 0.24);
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      width: 100%;
      z-index: 100;
      color: #1e88e5;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      margin-bottom: 10px;
      &.map-details {
        padding-bottom: 0px;
        height: 40px;
        margin: 0;
      }
    }
    .show-all-users-button {
      @extend .expand-list;
      position: relative;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid $q9_gray_light;
    }
  }

  .remove-item {
    $q9_text: #2e4a57;
    $q9_gray_dark: #adb8be;
    &:hover {
      .mat-icon {
        cursor: pointer;
        svg {
          path {
            fill: $q9_text;
          }
        }
      }
    }
    .mat-icon {
      height: 24px;
      width: 24px;
      svg {
        path {
          fill: $q9_gray_dark;
        }
      }
    }
  }

  .crop-text {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

.q9-select {
  &__top-30 {
    position: relative;
    top: 38px;
  }
  mat-option.mat-mdc-option {
    height: 30px;
    line-height: 20px;
  }
}

// MEDIA QUERIES

@media screen and (max-width: 768px) {
  .details-wrapper {
    position: static !important;
    margin-top: 20px;
    margin-left: 20px !important;
    width: calc(100% - 40px) !important;
  }
}
