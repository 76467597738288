@import 'variables';

.spinner-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 1000;
  text-align: center;
  &__spinner {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 40%;
  }
}

.content-spinner {
  display: flex;
  justify-content: center;
  padding: 20px 0;

  .spinner-placeholder {
    text-align: center;
    padding-right: 5px;
  }
}

.q9-modal-error {
  font-size: 14px;
  background-color: #f2dede;
  color: #a94442;
  border: 1px solid transparent;
  border-radius: 4px;

  .text {
    padding: 15px 20px;
  }
}

.q9-modal-subtitle {
  display: block;
  font-size: 10px;
  line-height: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.q9-modal-form-group {
  position: relative;
  z-index: 12;
  margin: 11px auto;
  &:last-child {
    margin-bottom: 0;
  }
}

.q9-label {
  display: block;
  color: $q9_brown;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 500;
}

.q9-section-data {
  color: $color-q9-section-data;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dt-role-checkbox {
  cursor: not-allowed;
  //
  //.mdc-checkbox {
  //  height: 13px;
  //  width: 13px;
  //  position: relative;
  //  top: -6px;
  //}
  .dt-role-checkbox-label {
    display: flex;
    flex-direction: column;
    .dt-role-checkbox-sublabel {
      font-style: italic;
      opacity: 0.6;
      line-height: 13px;
    }
  }
}

.bottom-margin {
  margin-bottom: 10px;
  &.align-top {
    position: relative;
    top: -7px;
  }
}

/*
Toolbar
 */
.actions-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  .refresh-btn {
    color: #959595;
    width: 32px;
    min-width: 32px;
    height: 32px;
    line-height: 20px;
    padding: 0;
    margin-left: 1px;
    margin-right: 5px;

    mat-icon {
      width: 100%;
      height: 100%;
      padding: 4px;
    }
  }
  .additional-action {
    display: flex;
    align-items: center;
    color: rgb(149, 149, 149);
    cursor: pointer;
  }
}

/**
NgSelect
 */

@mixin q9-tag-select($control-style) {
  .q9-form-field {
    padding-bottom: 0;

    &.ng-select-focused {
      .ng-select-container {
        .ng-arrow-wrapper .ng-arrow,
        .ng-value-container .ng-placeholder {
          color: $q9_primary;
        }
      }
    }

    &.invalid-on-submit {
      .ng-select-container {
        &:after {
          color: $q9_danger;
          border-bottom: 2px solid $q9_danger;
        }

        .ng-value-container {
          .ng-placeholder {
            color: $q9_danger;
          }
        }
      }
    }

    &.ng-invalid {
      &.ng-dirty {
        .ng-select-container:after {
          border-bottom: 2px solid $q9_danger;
        }

        .ng-placeholder {
          @extend .error-placeholder;
        }
      }

      &.ng-touched {
        .ng-select-container:after {
          border-bottom: 1px solid $q9_danger;
        }

        &.ng-select-focused {
          .ng-select-container:after {
            border-bottom: 2px solid $q9_danger;
          }

          .ng-arrow-wrapper .ng-arrow {
            color: $q9_danger;
          }
        }

        .ng-placeholder {
          @extend .error-placeholder;
        }
      }

      .error-placeholder {
        color: $q9_danger;
      }
    }

    &.q9-hide-label {
      &.ng-select-focused .ng-placeholder,
      .ng-select-container.ng-has-value .ng-placeholder {
        display: none;
      }
      .ng-value-container .ng-placeholder {
        transform: none;
        color: $q9_gray_dark;
      }
    }

    &.ng-select {
      .ng-select-container {
        @if ($control-style == 'filled') {
          border-radius: 4px 4px 0 0;
          background-color: rgba(0, 0, 0, 0.04);
          padding-top: 0.45em;

          .ng-arrow-wrapper,
          .ng-clear-wrapper {
            border-top: 0;
            padding-right: 0.75em;
          }
        }

        .ng-value-container {
          @if ($control-style == 'filled') {
            padding: 0.4375em 0.75em;
          }
        }
      }

      &:not(.invalid-on-submit) {
        .ng-select-container {
          &:hover {
            &:after {
              border-bottom: 2px solid rgba(0, 0, 0, 0.87);
            }
          }
        }
      }

      &.ng-select-multiple {
        .ng-select-container {
          display: flex;
          align-items: center;
        }

        .ng-value-container {
          .ng-input > input {
            font-size: 14px;
            color: $q9_text;
          }

          .ng-value {
            background-color: $q9_gray_light;
            color: $q9_text;
            .ng-value-icon,
            .ng-value-icon:hover {
              color: $q9_text;
            }
          }
        }
      }

      &.ng-select-single {
        .ng-value-container {
          .ng-input {
            padding-left: 0.75em;
          }
        }

        .ng-arrow-wrapper,
        .ng-clear-wrapper,
        .ng-spinner-loader {
          display: flex;
          align-self: center;
          top: 0;
        }
      }

      &.ng-select-focused .ng-select-container:after {
        border-color: $q9_primary;
      }

      &.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
        color: #fff;
        background-color: $q9_primary;
      }
    }
  }
  .error-wrapper {
    font-family: Source Sans Pro, sans-serif;
    font-size: 75%;
    padding: 0 1em;
  }
}

.q9-tag-wrapper {
  @include q9-tag-select(null);
}

.q9-tag-wrapper-filled {
  @include q9-tag-select('filled');
}

/**
End
 */

/**
Empty list
 */
.no-items {
  text-align: center;
  position: absolute;
  top: 40%;
  right: 0;
  left: 0;

  img {
    width: 90px;
  }

  h3 {
    color: #959595;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: -0.2px;
    margin: 36px 0 25px;

    a:hover {
      cursor: pointer;
    }
  }
}

/**
Some custom style for specific inputs
 */

.q9-filled-control {
  .mat-mdc-form-field-infix {
    border-radius: 4px 4px 0 0;
    background-color: rgba(0, 0, 0, 0.04);
  }
}

// TODO: move this fix to ui-core (Firefox)
.image-attachment-dialog {
  .drop-zone {
    height: 200px;
  }
}

/**
* dp-calendar (remove today btn style)
* TODO: rewrite
 */

::ng-deep .q9-date-picker-icon {
  mat-icon {
    height: 19px !important;

    .mat-datepicker-toggle-default-icon {
      height: 19px !important;
      width: 34px !important;
      color: #757575;
    }
  }
}

::ng-deep .dp-popup {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 8px;

  dp-day-time-calendar {
    &.dp-material {
      dp-time-select {
        border: none;
      }
    }
  }

  dp-day-calendar {
    .dp-day-calendar-container {
      border-radius: 2px;
    }

    &.dp-material {
      .dp-calendar-wrapper {
        border: none !important;
      }

      .dp-calendar-day {
        width: 36px;
        height: 36px;
        margin-bottom: 5px;
        margin-right: 5px;
      }

      .dp-calendar-week {
        .dp-calendar-day:last-child {
          margin-right: 0;
        }
      }

      .dp-weekdays {
        border-bottom: 1px solid #0000001f;

        .dp-calendar-weekday {
          width: 41px;
          margin: 5px 0 8px 0;
        }
      }
    }

    dp-calendar-nav {
      &.dp-material {
        .dp-nav-header-btn {
          border: 1px solid #0000000d;
          border-radius: 3px;
        }

        .dp-calendar-nav-container {
          border: none;
        }
      }

      .dp-current-location-btn {
        position: relative;
        top: -1px;
        height: 20px;
        width: auto;
        vertical-align: middle;
        background: #fff;
        border: 1px solid #0000000d;
        outline: none;
        border-radius: 3px;
        box-shadow: none;
        cursor: pointer;

        &:hover {
          background-color: #0000000d;
          color: #000;
        }

        &::after {
          content: 'Today';
        }
      }
    }

    .dp-calendar-wrapper {
      border: none;
    }
  }

  dp-month-calendar {
    &.dp-material {
      .dp-months-row {
        .dp-calendar-month {
          margin-right: 5px;
          margin-bottom: 5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.marketing-panel-container {
  margin: 7px 15px 0 15px;
  position: relative;

  .tns-outer {
    .tns-controls {
      button {
        top: 50%;
        border: 0;
        z-index: 1;
        width: 25px;
        height: 25px;
        opacity: 0.7;
        text-align: center;
        position: absolute;
        color: $color-white;
        background: $carousel-controls;

        &:hover {
          opacity: 0.9;
        }

        &:disabled {
          display: none;
        }
      }

      [data-controls='prev'] {
        left: 0;
      }

      [data-controls='next'] {
        right: 0;
      }
    }

    .tns-item {
      line-height: 0;

      &:last-child {
        padding-right: 0 !important;
      }

      img {
        height: 120px;
      }
    }
  }
}

q9-search-node {
  .toolbar-search-wrapper {
    display: flex;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
