/* You can add global styles to this file, and also import other style files */
@use 'sass/components/dialog';
@use 'sass/vendors/q9-material';
@use 'sass/vendors/q9-elements-ui-core';
@import '@q9elements/ui-core/styles.scss';
@import 'styles/rsb-details.scss';
@import 'styles/common.scss';
@import 'styles/q9-grid.scss';
@import 'variables.scss';

:root {
  --grid-card-size: 250px;
}

html,
body {
  --mat-option-selected-state-label-text-color: white !important;
  --mat-option-selected-state-layer-color: #00abfb !important;
  -webkit-font-smoothing: antialiased;
  -webkit-print-color-adjust: exact;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: none !important;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: var(--color-q9-section-data);
  background-color: $almost-white;
  overflow: auto;
}

* {
  box-sizing: border-box;
}

.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.full-width {
  width: 100%;
}
.row-full-width {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

:active,
:visited,
:focus {
  outline: none !important;
}

.text-center {
  text-align: center;
}

.img-circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #b5b5b5;
  span {
    color: white;
    display: block;
    margin-top: 5px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.img10_10 {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 11px;
  margin-top: 5px;
}

.pb-18 {
  padding-bottom: 18px;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.lh-32 {
  line-height: 32px !important;
}

.mbm-18 {
  margin-bottom: -18px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-23 {
  margin-bottom: 23px;
}

.d-flex {
  display: flex !important;
}

.dash-holder {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  height: 100%;
}

.bg-green {
  background-color: #00e676;
}
.bg-blue {
  background-color: #29b6f6;
}
.bg-red {
  background-color: #f62929;
}

button.q9-btn.try-pro-btn {
  font-weight: 500;
}

[hidden] {
  display: none !important;
}

/** MAKE SCROLLBAR ALWAYS VISIBLE */
.ps > .ps__rail-x,
.ps > .ps__rail-y {
  opacity: 0.6;
}

$link-color: #337ab7;
$link-hover-color: #23527c;
$link-hover-decoration: none;
a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

.break-word {
  word-wrap: break-word;
}

.no-display {
  display: none !important;
}

.q9-vertical-tabs .q9-tab-group.q9-icon-tab-group .mat-mdc-tab-body-wrapper {
  margin-top: 75px;
  padding: 0;
}

.item-details.q9-vertical-tabs {
  .sidebar-header {
    width: 360px;
  }
}

.no-value {
  font-style: italic;
  color: $q9_gray_dark;

  &.warn {
    color: $q9_danger;
  }

  &.progress {
    color: $q9_green;
  }
}
