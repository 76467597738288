@import '@q9elements/ui-core/constants';

$blue-arrow-icon: #00abf7;
$follow-info: #94a3a9;
$color-q9-section-data: #040404;
$link-color: #337ab7;
$link-hover-color: #23527c;
$link-hover-decoration: none;
$color-white: #fff;
$carousel-controls: #00a7f7;
$planned-release-lable: #337ab7;
$published-release-lable: #5cb85c;
$grey-dark: #959595;
$requirement-labels: #268432;
$default-state-star: #00abfb;
$default-state-stroke: #00abfb;
$followed-state-star: #00abfb;
$followed-stroke: #00abfb;
$favourite-state-star: #f5a623;
$favourite-stroke: #f5a623;
$black-15-opacity: rgba(black, 0.15);
$main-list-border-color: #53c7ff;
$grey-light: #eee;
$white: #fff;
$almost-white: #fafafa;
$q9-list-description: #a2a2a2;
$q9-tags-placeholder: #9f9f9f;
$q9-grey-border: #ddd;
$dash-color: #d2d2d2;
$team-name-color: #2e4a57;
$comment-count-color: #0293f3;
$blue-gray: #394b64;
$middle-gray: #7c8a9e;
$light-gray: #cfd8e6;
$dark-blue: #2d3241;
$light-shadow-blue: #30a3e3;
$green: #00c853;
$dark-purple: #73539e08;
$shadow-purple: #73539e1f;
$ocean-blue: #0056b3;

//logo colors
$logo-green: #009676;
$logo-blue: #01a3e3;
$logo-purple: #73539e;

$error: $q9_danger;

//mixins
@mixin priority-label {
  display: inline;
  padding: 6px;
  min-width: 60px;
  font-size: 14px;
  max-height: 35px;
  border-radius: 8px;
  line-height: 1;
  color: $color-white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: $requirement-labels;
}

// should be put in container for the element with @mixin image-aspect-ratio
@mixin aspect-ratio-polyfill {
  @supports not (aspect-ratio: 3 / 2) {
    &::after {
      display: block;
      content: '';
      padding-bottom: 66.6%;
    }

    img {
      object-fit: contain;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@mixin image-aspect-ratio {
  aspect-ratio: 3 / 2;
  object-fit: contain;
}

@mixin home-page-info-text {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin: 20px auto;
  letter-spacing: 1px;
  color: $follow-info;
  font-family: $q9_font_family;
}

@mixin iconAsBackground($backgroundRoute) {
  background: url($backgroundRoute);
  width: 32px;
  height: 32px !important;
  margin: 0;
  top: 0px;
}

@function black-with-opacity($opacity) {
  @return rgba(black, $opacity);
}

$training_blue-color: #00abfb;
$training_orange_color: #ffa500;
$training_pink_color: #b17373;
$training_green_color: #8bc34a;
