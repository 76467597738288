.q9-diloag--max-content {
  .mat-mdc-dialog-content.mdc-dialog__content {
    max-height: unset;
    height: 100%;
  }
}

.q9-search-tooltip .mdc-tooltip__surface {
  max-width: 200px !important;
}
