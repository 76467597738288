// grid variables https://www.telerik.com/design-system/docs/themes/theme-material/theme-variables/#color-system-variables
@import '@q9elements/ui-core/constants';

$max-row-height: 150px;
$kendo-color-primary: $q9-blue;
$kendo-color-error: $q9_danger;
$kendo-grid-selected-bg: rgba(0, 0, 0, 0.04);
$kendo-grid-hover-bg: $kendo-grid-selected-bg !default;
//$kendo-font-size: 12px;
$kendo-font-family: 'Source Sans Pro', sans-serif;
$kendo-grid-bg: white;
$kendo-checkbox-indeterminate-text: white;
$kendo-grid-sorted-bg: $kendo-grid-bg !default;
$kendo-grid-text: rgba(0, 0, 0, 0.5);
$kendo-grid-border: rgba(0, 0, 0, 0.12);
$kendo-grid-sticky-border: $kendo-grid-border !default;

$kendo-grid-column-menu-width: 200px !default;
$kendo-grid-column-menu-max-width: 200px !default;

@import '@progress/kendo-theme-material/dist/all';
